
import { defineComponent, PropType, ref } from 'vue'

import { v4 as uuidv4 } from 'uuid'

import MazSelect from '@web/components/maz/MazSelect.vue'

export default defineComponent({
  name: 'AcSelect',
  components: {
    MazSelect
  },
  props: {
    options: {
      type: Array as PropType<Record<string, string>[]>,
      default: () => []
    },
    name: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    modelValue: {
      type: [String, Number, Array],
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    search: {
      type: Boolean,
      default: false
    },
    searchPlaceholder: {
      type: String,
      default: null
    }
  },
  emits: ['update:modelValue'],
  setup (props, ctx) {
    const selectId = uuidv4()
    const refSelect = ref<HTMLSelectElement>()
    function onChange (value: string | number) {
      ctx.emit('update:modelValue', value)
    }

    function isSelected (value: string | number) {
      if (!props.options) throw new Error('[AcSelect] options should be provide')
      if (props.multiple && !Array.isArray(props.modelValue) && props.modelValue !== null) throw new Error('[AcSelect] value should be an array')
      if (!props.multiple && Array.isArray(props.modelValue)) throw new Error('[AcSelect] value should be a string or number')
      const values = props.modelValue
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ? props.multiple ? [...props.modelValue as any[]] : [props.modelValue]
        : []

      return values.includes(value)
    }

    function onInput (val) {
      ctx.emit('update:modelValue', val)
    }

    return {
      selectId,
      refSelect,
      isSelected,
      onChange,
      onInput
    }
  }
})
