import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "acSelect" }
const _hoisted_2 = {
  key: 0,
  class: "acSelect_label"
}
const _hoisted_3 = {
  key: 0,
  class: "acSelect_wrapper"
}
const _hoisted_4 = ["name", "id"]
const _hoisted_5 = {
  key: 0,
  class: "acSelect_option",
  disabled: ""
}
const _hoisted_6 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MazSelect = _resolveComponent("MazSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "label", {}, () => [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.search)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_MazSelect, {
            id: _ctx.selectId,
            modelValue: _ctx.modelValue,
            "onUpdate:modelValue": _ctx.onInput,
            options: _ctx.options,
            search: _ctx.search,
            placeholder: _ctx.placeholder,
            "search-placeholder": _ctx.searchPlaceholder
          }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "options", "search", "placeholder", "search-placeholder"])
        ]))
      : (_openBlock(), _createElementBlock("select", {
          key: 1,
          onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onChange($event.target.value))),
          class: "acSelect_select",
          ref: "refSelect",
          name: _ctx.selectId,
          id: _ctx.selectId
        }, [
          (_ctx.placeholder)
            ? (_openBlock(), _createElementBlock("option", _hoisted_5, _toDisplayString(_ctx.placeholder), 1))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "options", {}, () => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, key) => {
              return (_openBlock(), _createElementBlock("option", {
                key: key,
                value: option.value,
                class: "acSelect_option"
              }, _toDisplayString(option.label), 9, _hoisted_6))
            }), 128))
          ])
        ], 40, _hoisted_4))
  ]))
}