
import { defineComponent, ref, computed, nextTick, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'

import MazInput from '@web/components/maz/MazInput.vue'
import MazBtn from '@web/components/maz/MazBtn.vue'
/**
 * > Beautiful select input
 */
export default defineComponent({
  name: 'MazSelect',
  components: {
    MazInput,
    MazBtn
  },
  props: {
    modelValue: {
      required: true,
      validator: (prop: string | number | boolean | unknown[] | null) => ['number', 'string', 'boolean'].includes(typeof prop) || Array.isArray(prop) || prop === null
    },
    // list of the options
    options: { type: Array, required: true, default: () => [] },
    // When is `true` the select is disabled
    disabled: { type: Boolean, default: false },
    // When is `true` the select has the dark style
    dark: { type: Boolean, default: false },
    // Item in list height in pixel
    itemHeight: { type: Number, default: 35 },
    // List height in pixel
    listHeight: { type: Number, default: 260 },
    // List width in pixel or percent (:list-width="100", list-width="100%")
    listWidth: { type: [Number, String], default: null },
    // The select has no label in the input
    placeholder: { type: String, default: 'Select option' },
    // When is `true` the select you select multiple values
    noLabel: { type: Boolean, default: false },
    // When is `true` the select you select multiple values
    multiple: { type: Boolean, default: false },
    // When is `true` the select has an input to search in options
    search: { type: Boolean, default: false },
    // the search input placeholder
    searchPlaceholder: { type: String, default: 'Search in options' },
    // the search input placeholder
    color: { type: String, default: 'primary' },
    // input size
    size: { type: String, default: 'md' },
    // When is `true` the option list is open
    open: { type: Boolean, default: false },
    // set the position of option list (`top`, `top right`, `bottom right`)
    position: { type: String, default: 'left bottom' },
    // set label key and value key - Ex: `{ labelKey: '<your_object_key>', valueKey: '<your_object_key>', searchKey: '<your_object_key>' }`
    config: { type: Object, default: () => ({ labelKey: 'label', valueKey: 'value', searchKey: 'label' }) },
    // force value shown on input
    inputValue: { type: String, default: null }
  },
  emits: ['close', 'focus', 'open', 'input', 'keyup', 'blur', 'change', 'paste', 'click', 'update:modelValue'],
  setup (props, ctx) {
    const uniqueId = uuidv4()

    const root = ref<HTMLElement>()
    const SelectedTags = ref<HTMLElement>()
    const SelectedTagsContainer = ref<HTMLElement>()
    const SearchInput = ref<InstanceType<typeof MazInput>>()
    const optionsList = ref<HTMLElement>()

    const listIsOpen = ref(false)
    const query = ref('')
    const tmpValue = ref(null)
    const searchQuery = ref(null)
    const filteredOptions = ref<unknown[] | null>(null)

    const hasPositionTop = computed(() => props.position.includes('top'))
    const hasPositionRight = computed(() => props.position.includes('right'))
    const listTransition = computed(() => props.position.includes('bottom') ? 'maz-slide' : 'maz-slideinvert')
    const hasOpenList = computed(() => props.open || listIsOpen.value)
    const values = computed(() => {
      if (!props.options) throw new Error('[MazSelect] options should be provide')
      if (props.multiple && !Array.isArray(props.modelValue) && props.modelValue !== null) throw new Error('[MazSelect] value should be an array or null')
      if (!props.multiple && Array.isArray(props.modelValue)) throw new Error('[MazSelect] value should be a string, a number or null')
      return props.modelValue
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ? props.multiple ? [...props.modelValue as any[]] : [props.modelValue]
        : []
    })
    const hasLeftIcon = computed(() => ctx.attrs.leftIconName || ctx.slots['icon-left'])
    const placeholderShown = computed(() => props.multiple && values.value.length ? null : props.placeholder)
    const hasNoLabel = computed(() => props.multiple || props.noLabel)
    const optionHeight = computed(() => ({
      height: `${props.itemHeight}px`,
      flex: `0 0 ${props.itemHeight}px`
    }))
    const itemListSize = computed(() => {
      const width = !Number.isInteger(props.listWidth) ? props.listWidth : `${props.listWidth}px`
      return {
        maxHeight: `${props.listHeight}px`,
        width,
        maxWidth: width
      }
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tmpValueIndex = computed(() => optionsShown.value.findIndex((c: any) => c[props.config.valueKey] === tmpValue.value))
    const selectedValueIndex = computed(() => values.value.length
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ? props.options.findIndex((c: any) => c[props.config.valueKey] === values.value[values.value.length - 1])
      : null)
    const valueShown = computed(() => {
      if (props.inputValue) return props.inputValue
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const valueSelected: any = props.options.find((o: any) => o[props.config.valueKey] === props.modelValue)
      return valueSelected && valueSelected[props.config.valueKey] && !props.multiple
        ? valueSelected[props.config.labelKey]
        : values.value[0] ? ' ' : null
    })
    const optionsShown = computed(() => filteredOptions.value || props.options)
    const selectedOptions = computed(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const optionsSelected: any[] = []
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      values.value.forEach((v: any) => optionsSelected.push(props.options.find((o: any) => v === o[props.config.valueKey])))
      return optionsSelected
    })

    async function scrollTags () {
      await nextTick()
      if (SelectedTags.value) SelectedTags.value.scrollLeft = SelectedTagsContainer.value?.clientWidth ?? 0
    }

    function removeOption (val) {
      const leftValues = values.value.filter(v => v !== val)
      const valueToReturn = leftValues.length
        ? props.multiple ? leftValues : leftValues[0]
        : null
      emitValues(valueToReturn, false)
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function closeList (e: any = {}) {
      if (root.value?.contains(e.relatedTarget)) return e.preventDefault()
      ctx.emit('close')
      listIsOpen.value = false
      // isFocus.value = false
    }

    function openList (e) {
      ctx.emit('focus', e)
      if (!props.disabled) {
        if (props.disabled) return
        // sent when the list is open
        ctx.emit('open')
        // isFocus.value = true
        listIsOpen.value = true
        selectFirstValue()
        if (props.search) focusSearchInput()
        if (values.value.length) scrollToSelectedOnFocus(selectedValueIndex.value)
      }
    }

    function clearSearch () {
      searchQuery.value = null
      filteredOptions.value = null
    }
    async function reset () {
      clearSearch()
      if (props.multiple) return
      closeList()
    }
    function selectFirstValue () {
      if (props.modelValue || props.multiple) return
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const valueToReturn = (props.options[0] as any)[props.config.valueKey] || null
      tmpValue.value = valueToReturn
      emitValues(valueToReturn, true)
    }
    function updateValue (val) {
      if (values.value.includes(val) && props.multiple) {
        return removeOption(val)
      }
      tmpValue.value = val
      if (val) values.value.push(val)
      const valueToReturn = props.multiple && val ? values.value : val
      emitValues(valueToReturn, false)
    }
    async function focusSearchInput () {
      await nextTick()
      SearchInput.value?.$el?.querySelector('input')?.focus()
    }
    async function emitValues (vals, noReset) {
      // return the select input
      // @arg the option value selected
      ctx.emit('update:modelValue', vals)
      if (noReset) return
      await nextTick()
      reset()
    }
    async function scrollToSelectedOnFocus (arrayIndex) {
      await nextTick()
      optionsList.value?.scroll({
        top: arrayIndex * props.itemHeight - (props.itemHeight * 3)
      })
    }
    function keyboardNav (e) {
      const code = e.keyCode
      if (code === 40 || code === 38) {
        e.preventDefault()
        if (!hasOpenList.value) openList(e)
        let index = code === 40 ? tmpValueIndex.value + 1 : tmpValueIndex.value - 1
        if (index === -1 || index >= optionsShown.value.length) {
          index = index === -1
            ? optionsShown.value.length - 1
            : 0
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tmpValue.value = (optionsShown.value[index] as any)[props.config.valueKey]
        scrollToSelectedOnFocus(index)
      } else if (code === 13) {
        // enter key
        e.preventDefault()
        hasOpenList.value ? updateValue(tmpValue) : openList(e)
      } else if (code === 27) {
        // escape key
        closeList()
      } else if (!props.search) {
        // typing an option's name
        searching(e)
      }
    }
    function searching (e) {
      let queryTimer = 0
      const code = e.keyCode
      clearTimeout(queryTimer)
      queryTimer = setTimeout(() => {
        query.value = ''
      }, 2000)
      const q = String.fromCharCode(code)
      if (code === 8 && query.value !== '') {
        query.value = query.value.substring(0, query.value.length - 1)
      } else if (/[a-zA-Z-e ]/.test(q)) {
        if (!hasOpenList.value) openList(e)
        query.value += q.toLowerCase()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const resultIndex = props.options.findIndex((o: any) => {
          tmpValue.value = o[props.config.valueKey]
          return o[props.config.searchKey].toLowerCase().includes(query.value)
        })
        if (resultIndex !== -1) {
          scrollToSelectedOnFocus(resultIndex)
        }
      }
    }
    function searchInOptions (query) {
      searchQuery.value = query === '' ? null : query
      if (!searchQuery.value) {
        filteredOptions.value = props.options
        return filteredOptions.value
      }
      const _searchQuery = query.toLowerCase()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const _filteredOptions = props.options.filter((o: any) => (o[props.config.valueKey] && o[props.config.searchKey].toLowerCase().includes(_searchQuery)) || (o[props.config.labelKey] && o[props.config.labelKey].includes(_searchQuery)))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tmpValue.value = _filteredOptions.length ? (_filteredOptions[0] as any)[props.config.valueKey] : null
      filteredOptions.value = _filteredOptions
    }

    watch(() => props.modelValue, () => {
      if (props.multiple) scrollTags()
    })

    return {
      uniqueId,

      root,
      SelectedTags,
      SelectedTagsContainer,
      SearchInput,
      optionsList,

      listIsOpen,
      query,
      tmpValue,
      searchQuery,
      filteredOptions,

      hasPositionTop,
      hasPositionRight,
      listTransition,
      hasOpenList,
      values,
      hasLeftIcon,
      placeholderShown,
      hasNoLabel,
      optionHeight,
      itemListSize,
      tmpValueIndex,
      selectedValueIndex,
      valueShown,
      optionsShown,
      selectedOptions,

      scrollTags,
      removeOption,
      closeList,
      openList,
      clearSearch,
      selectFirstValue,
      updateValue,
      focusSearchInput,
      emitValues,
      scrollToSelectedOnFocus,
      keyboardNav,
      searching,
      searchInOptions
    }
  }
})
